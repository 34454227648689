import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import "./global.scss";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AdvantageClubCategoryList from "./pages/listAdvantageClubCategory";
import AdvantageClubList from "./pages/listAdvantageClub";
import { AuthProvider } from "./providers/auth";
import ChangePassword from "./pages/changePassword";
import ClientList from "./pages/clientList";
import { ConfigProvider } from "antd";
import ConfirmationToken from "./pages/confirmationToken";
import { CookiesProvider } from "react-cookie";
import CreateAdvantageClub from "./pages/createAdvantageClub";
import CreateAdvantageClubCategory from "./pages/createAdvantageClubCategory";
import CreateClient from "./pages/createClient";
import CreateFlux from "./pages/createFlow";
import CreateInstruction from "./pages/createInstruction";
import CreateResponsable from "./pages/createResponsable";
import CreateRole from "./pages/createRole";
import CreateService from "./pages/createService";
import Dashboard from "./pages/dashboard";
import Employee from "./pages/createEmployee";
import EmployeeList from "./pages/employeeList";
import FlowList from "./pages/listFlow";
import Home from "./pages/home";
import Interaction from "./pages/interaction";
import ListInstructions from "./pages/listInstructions/listInstructions";
import Login from "./pages/login";
import NotFound from "./pages/notfound";
import PrivateRoute from "./router";
import Profile from "./pages/profile";
import ProspectClient from "./pages/prospectClient";
import ProspectList from "./pages/prospectList";
import PublicProspect from "./pages/publicProspect";
import ReactDOM from "react-dom/client";
import RecoverPassword from "./pages/recoverPassword";
import Reports from "./pages/reports";
import ResetPassword from "./pages/resetPassword";
import ResponsableList from "./pages/responsableList";
import RoleList from "./pages/roleList";
import ServiceList from "./pages/serviceList";
import ShowClient from "./pages/showClient";
import Unauthorized from "./pages/unauthorized";
import UnreadInteractions from "./pages/reports/unreadInteractions";
import ViewAdvantageClub from "./pages/viewAdvantageClub";
import ViewAdvantageClubCategory from "./pages/viewAdvantageClubCategory";
import ViewClient from "./pages/viewClient";
import ViewEmployee from "./pages/viewEmployee";
import ViewInstructions from "./pages/viewInstructions";
import ViewProspect from "./pages/viewProspectClient";
import ViewResponsable from "./pages/viewResponsable";
import ViewRole from "./pages/viewRole";
import ViewService from "./pages/viewService";
import { getRoutesPermissions } from "./router/permissions";
import pt_BR from "antd/es/locale/pt_BR";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider locale={pt_BR}>
    <CookiesProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="/" exact element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/public-prospect" element={<PublicProspect />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/confirmation-token" element={<ConfirmationToken />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* Private Routes */}
            {/* Responsáveis */}
            <Route
              path="/responsable-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("responsable-list")}
                >
                  <ResponsableList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-responsable"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions(
                    "create-responsable"
                  )}
                >
                  <CreateResponsable />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-responsable/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-responsable")}
                >
                  <ViewResponsable />
                </PrivateRoute>
              }
            />

            {/* Funcionários */}
            <Route
              path="/employee-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("employee-list")}
                >
                  <EmployeeList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-employee"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-employee")}
                >
                  <Employee />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-employee/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-employee")}
                >
                  <ViewEmployee />
                </PrivateRoute>
              }
            />

            {/* Perfil */}

            <Route
              path="/role-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("role-list")}
                >
                  <RoleList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-role"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-role")}
                >
                  <CreateRole />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-role/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-role")}
                >
                  <ViewRole />
                </PrivateRoute>
              }
            />

            {/* Cliente */}

            <Route
              path="/client-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("client-list")}
                >
                  <ClientList />
                </PrivateRoute>
              }
            />

            <Route
              path="/show-client/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("show-client")}
                >
                  <ShowClient />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-client"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-client")}
                >
                  <CreateClient />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-client/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-client")}
                >
                  <ViewClient />
                </PrivateRoute>
              }
            />

            {/* Instrução */}

            <Route
              path="/instructions-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("instructions-list")}
                >
                  <ListInstructions />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-instruction"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions(
                    "create-instruction"
                  )}
                >
                  <CreateInstruction />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-instruction/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-instruction")}
                >
                  <ViewInstructions />
                </PrivateRoute>
              }
            />

            {/* Serviço/Etapa */}

            <Route
              path="/service-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("service-list")}
                >
                  <ServiceList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-service/"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-service")}
                >
                  <CreateService />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-service/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-service")}
                >
                  <ViewService />
                </PrivateRoute>
              }
            />

            {/* Fluxo */}
            <Route
              path="/flow-list/"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("flow-list")}
                >
                  <FlowList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-flow"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-flow")}
                >
                  <CreateFlux />
                </PrivateRoute>
              }
            />

            <Route
              path="/flow-list/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("flow-list")}
                >
                  <FlowList />
                </PrivateRoute>
              }
            />

            <Route
              path="/prospect-client"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("prospect-client")}
                >
                  <ProspectClient />
                </PrivateRoute>
              }
            />

            <Route
              path="/prospect-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("prospect-list")}
                >
                  <ProspectList />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-prospect/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("prospect-client")}
                >
                  <ViewProspect />
                </PrivateRoute>
              }
            />

            <Route
              path="/interaction/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("interaction")}
                >
                  <Interaction />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("profile")}
                >
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("dashboard")}
                >
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {/* Clube de Vantagens */}
            <Route
              path="/advantage-club-category-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("advantage-club-category-list")}
                >
                  <AdvantageClubCategoryList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-club-advantage-category"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-club-advantage-category")}
                >
                  <CreateAdvantageClubCategory />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-advantage-club"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-advantage-club")}
                >
                  <CreateAdvantageClub />
                </PrivateRoute>
              }
            />

            <Route
              path="/advantage-club-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("advantage-club-list")}
                >
                  <AdvantageClubList />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-advantage-club-category/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-advantage-club-category")}
                >
                  <ViewAdvantageClubCategory />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-advantage-club/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-advantage-club")}
                >
                  <ViewAdvantageClub />
                </PrivateRoute>
              }
            />


            <Route
              path="/general-flow-report"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("general-flow-report")}
                >
                  <Reports />
                </PrivateRoute>
              }
            />

            <Route
              path="/unread-interactions-report"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("unread-interactions-report")}
                >
                  <UnreadInteractions />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </CookiesProvider>
  </ConfigProvider>
);
